/** @jsx jsx */
import { jsx } from "theme-ui"

import Seo from "../components/seo"
import Layout from "../components/layout"

const USCC = () => (
  <Layout sx={usccStyles.usccPage}>
    <Seo title="USCC Web Hacking Course" />
    <div sx={usccStyles.wrapper}>
      <header>
        <h2 sx={{ color: "global.headingColor", fontSize: "24px" }}>USCC Web Hacking Course 2023</h2>
        
      </header>
      <div sx={usccStyles.bodySection}>
        <p sx={{ color: "global.textColor" }}>
          Welcome USCC campers! 
        </p>
        <p>Before class starts on Wednesday, July 19th, please: 
          <ol>
            <li>Download Burp Suite Community (free) Edition from <a sx={usccStyles.a} href="https://portswigger.net/burp/releases/professional-community-2023-4-4?requestededition=community&requestedplatform=">PortSwigger</a>.</li>
            <li>Sign up for an account at <a sx={usccStyles.a} href="https://uscc.lightfootlabs.io/">this CTFd server</a>. This CTFd server will be the central location for all labs. </li>
          </ol>
        </p>
        <p>You do not need to download a VM as you will for most other classes, because all web labs will be hosted online. </p>

        <p>Please email <a sx={usccStyles.a} href="mailto:jaime@lightfootlabs.io">jaime@lightfootlabs.io</a> if you have any questions or concerns. </p>
        <hr/>
        <p><a sx={usccStyles.a} href="/assets/uscc/USCC%20Web%20Hacking%20Slides.pdf">Presentation slides</a></p>
        <p><a sx={usccStyles.a} href="/assets/uscc/USCC%20Web%20Course%20-%20Dev%20Tools%20Guide.pdf">Dev Tools guide</a></p>
        <p><a sx={usccStyles.a} href="/assets/uscc/USCC%20Web%20Course%20-%20Burp%20Suite%20Guide.pdf">Burp Suite guide</a></p>
        <p><a sx={usccStyles.a} href="/assets/uscc/USCC%20Web%20Course%20-%20Resources%20Guide.pdf">Additional resources guide</a></p>
      </div>
    </div>
  </Layout>
)

export default USCC

const usccStyles = {
  usccPage: {
    pt: "30px",
    minHeight: "80vh",
  },
  wrapper: {
    variant: "variants.container",
    textAlign: "center",
  },
  icon: {
    fontSize: "128px",
    color: "brand",
  },
  bodySection: {
    textAlign: "left"
  },
  a: {
    color: "#7ab385",
    "&:hover": {
      color: "global.primaryLinkHoverColor",
    },
  },
}
